<template>
  <div
    class="InitialView"
    :style="style"
    @click="goNext()">
    <div class="spacerTop" />
    <div
      class="appIcon"
      :style="{ backgroundImage: `url(${theme.appImage})` }" />

    <div
      ref="top"
      class="top">
      <div
        class="exitButton --global-clickable"
        @click="exit()">
        <svgicon
          class="exitIcon"
          icon="dashboard-icon" />
      </div>
    </div>

    <div class="title">
      {{ $t('currentGame.title') }}
    </div>
    <div
      class="subtitle"
      v-html="$t('currentGame.subtitle')" />

    <div
      v-if="hasChallenge && !isLoading"
      class="buttons">
      <div
        class="button --global-clickable"
        @click="navigateToLevels()">
        {{ $t('playGame') }}
      </div>
      <div
        class="button --global-clickable"
        @click="navigateToChallenges()">
        {{ $t('challengeFriends') }}
      </div>
    </div>
    <div class="spacerBottom" />

    <div
      v-if="isLoading"
      class="loadingIndicator">
      <div class="">
        {{ $t('loadingIndicator') }}
      </div>
    </div>
  </div>
</template>

<translations>
  loadingIndicator: 'Loading...'
  loadingIndicator_no: 'Laster...'
  playGame: 'Play Game'
  challengeFriends: 'Challenge Friends'
</translations>

<script>
const MIN_TIME_BEFORE_CONTINUE = 2000;

export default {
    inject: ['theme'],
    computed: {
        style() {
            return {
                backgroundImage: this.theme.coverImage ? `url(${this.theme.coverImage})` : null
            };
        },
        isLoading() {
            return false;
        },
        hasChallenge() {
            return this.theme.hasChallenge;
        }
    },
    watch: {
        isLoading() {
            const timeSinceMounted = Date.now() - this.mountedTimestamp;
            if (timeSinceMounted < MIN_TIME_BEFORE_CONTINUE) {
                this.timeoutId = setTimeout(() => {
                    this.goNext();
                }, MIN_TIME_BEFORE_CONTINUE - timeSinceMounted);
            } else {
                this.goNext();
            }
        }
    },
    mounted() {
        if (this.isLoading) {
            this.mountedTimestamp = Date.now();
        } else {
            this.timeoutId = setTimeout(() => {
                this.goNext();
            }, MIN_TIME_BEFORE_CONTINUE);
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeoutId);
    },
    methods: {
        goNext() {
            if (this.isLoading) {
                return;
            }
            if (!this.hasChallenge) {
                this.navigateToLevels();
            }
        },
        navigateToLevels() {
            this.$router.push({
                name: 'MemogeniusGame-Levels'
            });
        },
        navigateToChallenges() {
            this.$router.push({
                name: 'MemogeniusGame-Challenges'
            });
        },
        exit() {
            this.$router.push(this.$store.state.moduleApp.memolife.gameExitTo);
        }
    }
};
</script>

<style scoped lang="scss">
.InitialView {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-size: cover;
    background-position: center bottom;
}

.exitIcon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: white;
}

.top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: $memogeniusShared-headerHeight;
    display: flex;
    align-items: center;
}
.exitButton {
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 0 1em;
    padding-left: 2em;
}

.spacerTop {
    flex: 0.8;
}
.spacerBottom {
    flex: 1;
}

.appIcon {
    $size: 6em;
    width: $size;
    height: $size;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin-bottom: 1em;
}

.title {
    font-size: 200%;
}
.subtitle {
    margin-top: 0.5em;
    color: rgba(white, 0.7);
}

.loadingIndicator {
    position: absolute;
    margin-top: 1em;
    bottom: 2em;
    color: rgba(white, 0.5);
    left: 0;
    right: 0;
    text-align: center;

    &.hide {
        opacity: 0;
    }
}

.buttons {
    margin-top: 3em;
    width: 100%;
    max-width: 16em;
}
.button {
    padding: 1em;
    background-color: white;
    color: blue;
    text-align: center;
    border-radius: 2em;

    &:last-child {
        margin-top: 1em;
    }
}
</style>
